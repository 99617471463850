import React from 'react';

import CurveDeskImg from 'common/src/assets/image/hosting/Background/Overview/CurveDeskImage.svg';
import CurveMobImg from 'common/src/assets/image/hosting/Background/Overview/CurveMobImage.svg';
import DiamondDeskImg from 'common/src/assets/image/hosting/Background/Overview/DiamondDeskImage.svg';
import DiamondMobImg from 'common/src/assets/image/hosting/Background/Overview/DiamondMobImage.svg';

const BgImageLight = () => {
  return (
    <aside className="bg-images section_background_white">
      <img
        className="bg-image"
        src={CurveDeskImg}
        alt="Background image of a diamond shape"
        alt="background 1"
      />
      <img
        className="bg-image"
        src={DiamondDeskImg}
        alt="Background image of a diamond shape"
        alt="background 2"
      />
      <img
        className="bg-image bg-image--mobile"
        src={CurveMobImg}
        alt="Background image of a diamond shape"
        alt="background 1"
      />
      <img
        className="bg-image bg-image--mobile"
        src={DiamondMobImg}
        alt="background 2"
      />
    </aside>
  );
};

export default BgImageLight;
