import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
// import Heading from 'common/src/components/Heading';
// import Button from 'common/src/components/Button';
// import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import { useStaticQuery, graphql } from 'gatsby';

import IconFloatingApplication from '../IconFloating/Applications';
import BgImageDark from '../BgImagedark';

// import sample from 'common/src/assets/image/hosting/author-3.jpg';

import BgImageLight from '../BgImageLight';

import './infoapplication.css';

const InfoApplicationSection = ({
  name,
  sectionWrapper,
  ipasectionWrapper,
  row,
  col,
  title,
  description,
  ipadescription,
  individualbox,
  usecasedescription,
  white_title,
  white_andmore,
  button,
  textArea,
  contentArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  secTitleWrapper,
  secHeading,
  secText,
  box_size,
  andmore,
}) => {
  const Data = useStaticQuery(
    graphql`
      query {
        hostingJson {
          INFO_APPLICATION2 {
            FormRecognition {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            IntelligentEmail {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            CheckRecognition {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            QuoteProcessing {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            CaseDeflection {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            InvoiceProcessing {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            LeaseAbstraction {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            IDVerification {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            WorkflowAutomation {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            CustomerServiceAutomation {
              class
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          Usecase {
            Document_processing_understanding {
              title
              image {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            IPA {
              title
              image {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <Box {...sectionWrapper}>
      {Data.hostingJson.INFO_APPLICATION2[name].map((item, index) => (
        <Box
          key={`applications-${index}`}
          className={`multi_sections_container ${item.class}`}
        >
          <Box className="overview_section">
            <BgImageLight />
            <Container>
              <div className="empty_space40" />
              {/* <Heading {...title} content={item.title} /> */}
              <Box {...row}>
                <Box {...col}>
                  <Fade left cascade>
                    <Box className="InfoBanner_Image_container">
                      <Image
                        src={item.avatar.childImageSharp.fluid.src}
                        alt={`${item.title} from RAP`}
                        className="icon_image"
                      />
                    </Box>
                  </Fade>
                </Box>
                <Box {...col}>
                  <Fade right cascade>
                    {/* <Heading {...title} content="Overview" /> */}
                    <Text
                      {...description}
                      className="text_justify"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </Fade>
                </Box>
              </Box>
              <div className="empty_space40" />
            </Container>
          </Box>
          {/* -------------------------------------------------------------------
                                    Only for IPA Page
          ------------------------------------------------------------------------*/}
          {name === 'WorkflowAutomation' ? (
            <Box {...ipasectionWrapper}>
              <Container>
                <Fade bottom cascade>
                  <Text {...title} content="Potential Use Cases"></Text>
                  <Text
                    {...ipadescription}
                    content=" Processes that are highly-repetitive and data-centric are good
                  candidates for Intelligent Process Automation. When they are a
                  section of a workflow, the entire workflow can be automated by
                  using RPA to automate human-like actions and feed inputs and
                  outputs to various processes in the workflow for end-to-end
                  process automation across several industries."
                  ></Text>
                </Fade>
              </Container>

              <Container>
                <Fade bottom cascade>
                  <Box {...box_size}>
                    {Data.hostingJson.Usecase['IPA'].map((item, index) => (
                      <Box {...individualbox} className="applicationusecasebox">
                        <Image
                          src={item.image.childImageSharp.fluid.src}
                          alt={`icon-${index}`}
                        />
                        <Text
                          {...usecasedescription}
                          content={item.title}
                        ></Text>
                      </Box>
                    ))}
                  </Box>
                  <Fade bottom cascade>
                    <Text {...andmore} content="and more ..." />
                  </Fade>
                </Fade>
              </Container>
            </Box>
          ) : null}
          {/* -------------------------------------------------------------------
                                    Only for IPA Page
          ------------------------------------------------------------------------*/}

          {/* -------------------------------------------------------------------
                                    Only for Document understanding
          ------------------------------------------------------------------------*/}

          {name === 'IDVerification' ? (
            <Box {...ipasectionWrapper}>
              <Container>
                <Fade bottom cascade>
                  <Text {...title} content="Example Use Cases"></Text>
                </Fade>
              </Container>
              <Container>
                <Fade bottom cascade>
                  <Box {...box_size}>
                    {Data.hostingJson.Usecase[
                      'Document_processing_understanding'
                    ].map((item, index) => (
                      <Box {...individualbox} className="applicationusecasebox">
                        <Image
                          src={item.image.childImageSharp.fluid.src}
                          alt={`icon-${index}`}
                        />
                        <Text
                          {...usecasedescription}
                          content={item.title}
                        ></Text>
                      </Box>
                    ))}
                    <Text {...andmore} content="and more ..." />
                  </Box>
                </Fade>
              </Container>
            </Box>
          ) : null}

          {/* -------------------------------------------------------------------
                                    Only for Document understanding
          ------------------------------------------------------------------------*/}
          <Box className="section_background_blue benefit_section">
            <BgImageDark />
            <Container>
              {name === 'CustomerServiceAutomation' ? (
                <Text {...white_title} content="Example Use Cases"></Text>
              ) : null}
              <Box>
                <div className="empty_space20" />
                <IconFloatingApplication name={name} />
                <div className="empty_space20" />
              </Box>
              {name === 'CustomerServiceAutomation' ? (
                <Text {...white_andmore} content="and more ..." />
              ) : null}
            </Container>
          </Box>

          {/* -------------------------------------------------------------------
                                    Only for Customer service automation
          ------------------------------------------------------------------------*/}
          {name === 'CustomerServiceAutomation' ? (
            <Box className="benefit_section">
              {/* <BgImageDark /> */}
              <Container>
                <Box>
                  <div className="empty_space20" />
                  <IconFloatingApplication name="IntelligentEmail" />
                  <div className="empty_space20" />
                </Box>
              </Container>
            </Box>
          ) : null}
          {/* -------------------------------------------------------------------
                                    Only for Customer service automation
          ------------------------------------------------------------------------*/}
        </Box>
      ))}
    </Box>
  );
};

InfoApplicationSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  imageAreaRow: PropTypes.object,
  imageWrapper: PropTypes.object,
  imageWrapperOne: PropTypes.object,
  imageWrapperTwo: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  box_size: PropTypes.object,
};

InfoApplicationSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    // pt: ['25px', '25px', '25px', '30px', '30px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'info_application',
  },
  ipasectionWrapper: {
    as: 'section',
    pt: ['25px', '25px', '25px', '25px', '25px'],
    pb: ['25px', '25px', '25px', '25px', '25px'],
  },
  secTitleWrapper: {
    mb: ['50px', '60px', '60px', '75px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#eb4d4b',
    mb: '10px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  textArea: {
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  contentArea: {
    width: ['100%', '100%', '80%', '80%', '80%'],
    margin: '0 auto',
  },
  imageArea: {
    width: ['100%', '100%', '50%'],
    flexBox: true,
    flexDirection: 'row-reverse',
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    ml: '-200px',
  },
  imageWrapperTwo: {
    alignSelf: 'flex-end',
    mb: '-60px',
  },
  title: {
    fontSize: ['30px', '30px', '30px', '30px', '30px'],
    fontWeight: '300',
    color: '#000',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '0px',
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mb: '0px',
  },
  ipadescription: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mb: '0px',
    textAlign: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `{5}`,
  },
  box_size: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    mt: '35px',
  },
  individualbox: {
    width: ['98%', '98%', '23%', '23%', '23%'],
    ml: '1%',
    mr: '1%',
    mb: '25px',
    flexBox: true,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  usecasedescription: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mt: '10px',
    textAlign: 'center',
  },
  andmore: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    textAlign: 'center',
    color: '#000000',
  },
  white_title: {
    fontSize: ['30px', '30px', '30px', '30px', '30px'],
    fontWeight: '300',
    color: '#fff',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '0px',
    textAlign: 'center',
  },
  white_andmore: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    textAlign: 'center',
    color: '#fff',
  },
};

export default InfoApplicationSection;
