import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import BannerWrapper from '../banner.style';
import { useStaticQuery, graphql, Link } from 'gatsby';

// import './bannerother.css';

const BannerSectionApplication = ({
  name,
  row,
  title,
  description,
  subdescription,
  button,
  textArea,
}) => {
  const Data = useStaticQuery(
    graphql`
      query {
        hostingJson {
          BANNER_APPLICATIONS2 {
            FormRecognition {
              title
              subtitle
              description
              class
            }
            IntelligentEmail {
              title
              subtitle
              description
              class
            }
            CheckRecognition {
              title
              subtitle
              description
              class
            }
            QuoteProcessing {
              title
              subtitle
              description
              class
            }
            CaseDeflection {
              title
              subtitle
              description
              class
            }
            InvoiceProcessing {
              title
              subtitle
              description
              class
            }
            LeaseAbstraction {
              title
              subtitle
              description
              class
            }
            IDVerification {
              title
              subtitle
              description
              class
            }
            BuildYourOwn {
              title
              subtitle
              description
              class
            }
            WorkflowAutomation {
              title
              subtitle
              description
              class
            }
            CustomerServiceAutomation {
              title
              subtitle
              description
              class
            }
          }
        }
      }
    `
  );
  return (
    <>
      {Data.hostingJson.BANNER_APPLICATIONS2[name].map((widget, index) => (
        <BannerWrapper
          key={`banner-${index}`}
          className={`banner_container banner_bg ${widget.class}`}
        >
          <Container>
            <Box {...row}>
              <Box {...textArea}>
                <Text {...title} content={widget.title} />
                <Heading {...description} content={widget.subtitle} />
                {/* <Text {...subdescription} content={item.description} /> */}
                {/* <Box>
                  <Link to="/bookademo" className="btn btn_tertiary">
                    <span className="btn-text">Book a Demo</span>
                  </Link>
                </Box> */}
              </Box>
            </Box>
          </Container>
        </BannerWrapper>
      ))}
    </>
  );
};

BannerSectionApplication.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  subdescription: PropTypes.object,
  button: PropTypes.object,
};

BannerSectionApplication.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textArea: {
    width: [1, '95%', '95%', '90%', '90%'],
  },
  title: {
    fontSize: ['30px', '30px', '30px', '30px', '30px'],
    fontWeight: 'normal',
    // color: '#0f2137',
    color: '#fff',
    letterSpacing: '-0.025em',
    mt: ['20px', '20px', '25px', '25px', '25px'],
    mb: ['20px', '20px', '25px', '25px', '25px'],
    lineHeight: '1.31',
    textAlign: 'center',
  },
  description: {
    fontSize: ['26px', '32px', '35px', '35px', '35px'],
    // color: '#343d48cc',
    color: '#fff',
    lineHeight: '1.2',
    // mb: ['8px', '8px', '10px', '10px', '10px'],
    mt: ['20px', '20px', '25px', '25px', '25px'],
    mb: ['40px', '40px', '50px', '50px', '50px'],
    textAlign: 'center',
  },
  subdescription: {
    fontSize: ['14px', '14px', '14px', '16px', '16px'],
    // color: '#343d48cc',
    color: '#fff',
    lineHeight: '1.75',
    mb: ['20px', '20px', '25px', '25px', '25px'],
    textAlign: 'center',
  },
  button: {
    title: 'Search',
    type: 'button',
    fontSize: '18px',
    fontWeight: '500',
    color: '#fff',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    iconPosition: 'left',
  },
};

export default BannerSectionApplication;
