import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
// import Text from 'common/src/components/Text';
// import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';

import { useStaticQuery, graphql } from 'gatsby';

// import sample from 'common/src/assets/image/hosting/author-1.jpg';

import '../iconfloating.css';

const IconFloatingApplication = ({
  name,
  sectionWrapper,
  row,
  col,
  col20,
  col30,
  col70,
  iconHead,
  iconsubHead,
  iconDescription,
  textArea,
}) => {
  const Data = useStaticQuery(
    graphql`
      query {
        hostingJson {
          APPLICATION_ICON2 {
            FormRecognition {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            IntelligentEmail {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            CheckRecognition {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            QuoteProcessing {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            CaseDeflection {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            InvoiceProcessing {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            LeaseAbstraction {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            IDVerification {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            WorkflowAutomation {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            CustomerServiceAutomation {
              title
              description
              avatar {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <>
      <Box {...sectionWrapper} className={`Icon_Floating_Container ${name}`}>
        <Fade bottom cascade delay={10}>
          <Box {...row} className="margin_auto flexjustifyaround">
            {Data.hostingJson.APPLICATION_ICON2[name].map((widget, index) => (
              <Box
                {...col}
                className="icontext_card"
                key={`applications-${index}`}
              >
                <Box {...row} className="flexaligncenter">
                  <Box {...col30}>
                    <Image
                      src={widget.avatar.childImageSharp.fluid.src}
                      alt={`icon-${index}`}
                      className="icon_image"
                    />
                  </Box>
                  <Box {...col70}>
                    <ul>
                      <li {...iconHead}>{widget.title}</li>
                      <li {...iconDescription}>{widget.description}</li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Fade>
      </Box>
    </>
  );
};

IconFloatingApplication.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col1: PropTypes.object,
  col: PropTypes.object,
  iconHead: PropTypes.object,
  iconsubHead: PropTypes.object,
  iconDescription: PropTypes.object,
  textArea: PropTypes.object,
};

IconFloatingApplication.defaultProps = {
  sectionWrapper: {
    as: 'div',
    id: 'IconFloating_section',
    className: 'IconFloating_section',
    pt: ['15px', '15px', '15px', '15px'],
    background: 'transparent',
    // pb: ['60px', '80px', '80px', '100px'],
  },

  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    className: 'col',
    width: [1, 0.45, 0.45, 0.45],
    pr: '15px',
    pl: '15px',
    mb: '20px',
  },
  col20: {
    className: 'col20',
    width: [0.4, 0.15, 0.15, 0.2],
  },
  col30: {
    className: 'col30',
    width: [1, 1, 0.3, 0.3],
  },
  col70: {
    className: 'col70',
    width: [1, 1, 0.7, 0.7],
  },
  iconHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconHead',
  },
  iconsubHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconsubHead',
  },
  textArea: {
    width: ['100%', '85%', '85%', '85%', '80%'],
    margin: '0 auto',
  },
  iconDescription: {
    className: 'iconDescription',
    fontSize: ['18px', '18px', '20px', '20px'],
  },
};

export default IconFloatingApplication;
